<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addNews'})" :showFilter="false" :showSearch="false" :showExportation="false">{{ $t('auth.news') }}</dashboard-page-title>

    <main-table :fields="fields" :baseURL="VUE_APP_WEBSITE_LINK" :list_url="'admin/news'"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_WEBSITE_LINK } from '@/config/constant'
export default {
  name: 'events',
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_WEBSITE_LINK,
      fields: [
        { label: 'الصور', key: 'featured_image', type: 'image', class: 'text-right' },
        { label: 'العنوان', key: 'title', type: 'name', class: 'text-right' },
        { label: 'وصف مختصر', key: 'excerpt', class: 'text-right' },
        { label: 'التفاصيل', key: 'content', class: 'text-right' },
        { label: 'عدد المشاهدات', key: 'views_count', type: 'number', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'edit',
              icon: 'ri-ball-pen-fill',
              color: 'info',
              ifNavigate: true,
              routePath: 'edit-news/:id'
            },
            {
              text: 'Delete',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: 'admin/news',
              baseUrl: VUE_APP_WEBSITE_LINK,
              titleHeader: 'منتج',
              question: 'هل متأكد أنك تريد مسح هذا العنصر',
              textContent: 'name',
              textDeleteButton: 'بالتأكيد, مسح',
              textCancelButton: 'الغاء',
              showAlert: true
            }
          ]
        }
      ]
    }
  },
  methods: {

  }
}
</script>
